//  Clipboard!
var ClipboardJS = require('clipboard/dist/clipboard.min.js');

function createClipboards() {
  var clipboard = new ClipboardJS('.clipboard');
  clipboard.on('success', function (e: any) {
    var trigger = e.trigger;
    // Get the previous tooltip text.
    var previousTooltip = trigger.dataset.tooltip;
    // Set the tooltip text to 'Copied' and display it for a bit.
    trigger.dataset.tooltip = 'Copied!';
    trigger.focus();
    // On Mouse out, remove the forced focus state, and return the tooltip to the original text.
    trigger.addEventListener('mouseleave', function () {
      trigger.blur();
      setTimeout(function () {
        trigger.dataset.tooltip = previousTooltip;
      }, 500)
    })
    e.clearSelection();
  })
}

document.addEventListener("DOMContentLoaded", function () {
  try {
    createClipboards();
  } catch (error) {
    throw Error(error);
  }
});