// timeout id for nice show/hide handling
var hideMenuTimeout: any;

function showMenu(anchor: HTMLElement, menu: HTMLElement):void {
  menu.style.display = "block";
  menu.style.top = `calc(${anchor.offsetTop}px + .5rem)`;
  menu.style.right = `${anchor.offsetWidth + 40}px`;
}

function hideMenu(menu: HTMLElement, time?: number):void {
  hideMenuTimeout = setTimeout(() => {
    menu.style.display = "none";
  }, time ? time : 300);
}

// TODO: make subnavs take up the entirety of the enu when the screen size reaches a threshold just larger that the width of the menu

document.addEventListener("DOMContentLoaded", function() {

  // Gather all subnavAnchors
  var subnavs: any = document.getElementsByClassName("subnav");

  // iterate over them and position their submenus next to them
  for (let subnavAnchor of subnavs) {

    // find the Menu that is referenced by the subnav list-item
    var menu: any = [].slice.call(document.querySelectorAll(`[name=${subnavAnchor.id}]`))[0];

    // On list-item hover, show menu
    subnavAnchor.addEventListener("mouseover", () => {
      clearTimeout(hideMenuTimeout);
      showMenu(subnavAnchor, menu);
    });

    // Hide menu when user no longer hovers on subnav anchor item.
    subnavAnchor.addEventListener("mouseleave", () => {
      hideMenu(menu);
    });

    // Override hide timeout from exiting the subnav anchor element to keep menu displayed.
    menu.addEventListener("mouseover", () => {
      clearTimeout(hideMenuTimeout);
      showMenu(subnavAnchor, menu);
    });

    // Hide menu when mouse leaves.
    menu.addEventListener("mouseleave", () => {
      hideMenu(menu);
    })

    // If a user clicks on a menuItem, prevent it from floating
    menu.addEventListener("click", () => {
      hideMenu(menu, 0);
    })
  }
});